import React from 'react'

const Spanish_Language_Icon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={"icons svg "+props.className} width={props.width} height={props.height} viewBox='0 0 128 128' aria-labelledby={props.iconTitle}>
    <title id={props.iconTitle}>Spanish_Language Icon</title>
    <path d='M84,5H16C9.9,5,5,9.6,5,15.3V67c0,5.7,4.9,10.3,11,10.3h7.2v15.3c0,0.9,0.6,1.8,1.5,2.2c0.4,0.2,0.7,0.2,1.1,0.2 c0.6,0,1.2-0.2,1.7-0.5l22.7-17.2H84c6.1,0,11-4.6,11-10.3V15.3C95,9.6,90.1,5,84,5z M89.9,66.9c0,3-2.6,5.4-5.8,5.4H49.2 c-0.6,0-1.2,0.2-1.7,0.5L28.4,87.3V74.7c0-1.3-1.2-2.4-2.6-2.4h-9.8c-3.2,0-5.8-2.4-5.8-5.4V15.5c0-3,2.6-5.4,5.8-5.4h68.2 c3.2,0,5.8,2.4,5.8,5.4V66.9z'
    />
    <polygon points='32.1,42.7 46.3,42.7 46.3,37.7 32.1,37.7 32.1,31.5 47.5,31.5 47.5,26.3 26.1,26.3 26.1,55.5 48.3,55.5 48.3,50.2 32.1,50.2'
    />
    <path d='M73.2,41c-1.1-0.9-2.8-1.6-5-2.1L63,37.7c-2-0.5-3.3-0.9-3.9-1.2c-0.9-0.5-1.4-1.3-1.4-2.3c0-1.1,0.5-2,1.4-2.7 c1-0.6,2.2-1,3.9-1c1.4,0,2.7,0.2,3.6,0.7c1.5,0.7,2.2,2,2.4,3.7h5.9c-0.1-3.1-1.3-5.5-3.5-7.1c-2.2-1.6-4.9-2.4-8-2.4 c-3.7,0-6.6,0.9-8.5,2.6c-1.9,1.7-2.9,3.9-2.9,6.5c0,2.9,1,5,3,6.3c1.2,0.8,3.3,1.6,6.4,2.2l3.2,0.7c1.9,0.4,3.2,0.9,4.1,1.4 c0.9,0.5,1.3,1.3,1.3,2.2c0,1.7-0.9,2.8-2.6,3.4c-0.9,0.3-2.1,0.5-3.6,0.5c-2.5,0-4.2-0.6-5.2-1.8c-0.6-0.7-0.9-1.7-1.1-3h-5.8 c0,3,1.1,5.4,3.3,7.2s5.2,2.6,9.1,2.6c3.8,0,6.7-0.9,8.7-2.6s3.1-4,3.1-6.6C75.8,44.4,74.9,42.4,73.2,41z'
    />
  </svg>
)

export default Spanish_Language_Icon