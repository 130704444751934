import React from 'react'

const BBQ_Icon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={"icons svg "+props.className} width={props.width} height={props.height} viewBox='0 0 100 100' aria-labelledby={props.iconTitle}>
    <title id={props.iconTitle}>Barbecue Icon</title>
    <path d='M68.021,73.852c-5.825,0-10.564,4.739-10.564,10.564c0,5.826,4.739,10.563,10.564,10.563s10.564-4.737,10.564-10.563 C78.585,78.591,73.846,73.852,68.021,73.852z M68.021,89.42c-2.76,0-5.004-2.245-5.004-5.004s2.244-5.005,5.004-5.005 c2.759,0,5.005,2.246,5.005,5.005S70.779,89.42,68.021,89.42z'
    />
    <path d='M47.951,66.229c2.297,0,4.529-0.283,6.665-0.812l3.737,8.514c1.82-1.682,4.079-2.892,6.583-3.446l-3.424-7.805 c8.455-4.757,14.179-13.817,14.179-24.191c0-1.536-1.243-2.78-2.78-2.78H22.991c-1.535,0-2.78,1.245-2.78,2.78 c0,10.374,5.725,19.434,14.179,24.191L22.44,89.919c-0.823,1.874,0.03,4.061,1.905,4.882c0.484,0.214,0.99,0.314,1.488,0.314 c1.426,0,2.786-0.828,3.396-2.22l2.109-4.806h22.894c-0.314-1.173-0.483-2.404-0.483-3.674c0-1.294,0.177-2.548,0.502-3.74H34.59 l6.695-15.259C43.421,65.945,45.653,66.229,47.951,66.229z M25.944,41.269h8.23c0.855,8.463,4.955,15.59,10.615,19.175 C34.957,59.034,27.187,51.152,25.944,41.269z'
    />
    <path d='M30.164,26.203c0.235,0.665,0.549,1.269,0.9,1.823c0.356,0.552,0.752,1.054,1.185,1.511c0.43,0.457,0.891,0.876,1.394,1.239 c0.123,0.088,0.283,0.124,0.44,0.083c0.281-0.074,0.449-0.361,0.376-0.641l0-0.001c-0.152-0.584-0.312-1.135-0.451-1.68 c-0.136-0.543-0.257-1.072-0.34-1.584c-0.173-1.023-0.185-1.964-0.014-2.754c0.17-0.794,0.504-1.48,1.004-2.253 c0.497-0.771,1.163-1.596,1.867-2.563c0.697-0.965,1.451-2.096,1.952-3.489c0.246-0.693,0.424-1.445,0.482-2.208 c0.064-0.762,0.012-1.528-0.133-2.245c-0.293-1.442-0.932-2.67-1.69-3.705c-0.386-0.517-0.799-0.993-1.248-1.428 c-0.446-0.436-0.919-0.836-1.436-1.186c-0.122-0.082-0.278-0.113-0.431-0.073c-0.281,0.075-0.449,0.362-0.374,0.644l0.002,0.006 c0.155,0.585,0.326,1.14,0.482,1.686c0.153,0.545,0.301,1.077,0.413,1.597c0.237,1.035,0.36,2.018,0.304,2.87 c-0.056,0.858-0.266,1.565-0.637,2.311c-0.368,0.744-0.916,1.536-1.56,2.436c-0.637,0.901-1.376,1.91-1.988,3.161 c-0.304,0.624-0.567,1.312-0.739,2.045c-0.17,0.732-0.245,1.507-0.197,2.26C29.771,24.815,29.927,25.54,30.164,26.203z'
    />
    <path d='M43.835,26.203c0.235,0.665,0.549,1.269,0.9,1.823c0.356,0.552,0.752,1.054,1.183,1.511 c0.431,0.457,0.893,0.876,1.395,1.239c0.122,0.088,0.283,0.124,0.44,0.083c0.281-0.074,0.45-0.361,0.376-0.641l-0.002-0.001 c-0.151-0.584-0.311-1.135-0.45-1.68c-0.135-0.543-0.258-1.072-0.34-1.584c-0.173-1.023-0.185-1.964-0.013-2.754 c0.169-0.794,0.503-1.48,1.003-2.253c0.496-0.771,1.162-1.596,1.867-2.563c0.695-0.965,1.45-2.096,1.95-3.489 c0.247-0.693,0.425-1.445,0.483-2.208c0.063-0.762,0.012-1.528-0.133-2.245c-0.294-1.442-0.932-2.67-1.69-3.705 c-0.387-0.517-0.799-0.993-1.248-1.428c-0.447-0.436-0.919-0.836-1.436-1.186C48,5.039,47.844,5.007,47.691,5.048 c-0.281,0.075-0.448,0.362-0.374,0.644l0.001,0.006c0.155,0.585,0.326,1.14,0.483,1.686c0.152,0.545,0.299,1.077,0.413,1.597 c0.235,1.035,0.358,2.018,0.304,2.87c-0.057,0.858-0.266,1.565-0.638,2.311c-0.367,0.744-0.915,1.536-1.559,2.436 c-0.638,0.901-1.376,1.91-1.989,3.161c-0.303,0.624-0.567,1.312-0.739,2.045c-0.171,0.732-0.245,1.507-0.198,2.26 C43.443,24.815,43.598,25.54,43.835,26.203z'
    />
    <path d='M57.507,26.203c0.234,0.665,0.549,1.27,0.9,1.823c0.355,0.552,0.752,1.054,1.184,1.511c0.43,0.457,0.891,0.876,1.394,1.239 c0.122,0.088,0.283,0.124,0.44,0.083c0.28-0.074,0.448-0.361,0.376-0.641L61.8,30.216c-0.153-0.584-0.313-1.135-0.451-1.68 c-0.136-0.543-0.257-1.071-0.34-1.584c-0.173-1.023-0.186-1.964-0.013-2.754c0.168-0.794,0.504-1.48,1.002-2.253 c0.498-0.771,1.163-1.596,1.867-2.563c0.697-0.965,1.452-2.096,1.952-3.489c0.246-0.693,0.425-1.445,0.483-2.208 c0.063-0.762,0.012-1.528-0.135-2.245c-0.292-1.443-0.931-2.671-1.688-3.706c-0.387-0.517-0.801-0.993-1.249-1.428 c-0.447-0.436-0.919-0.836-1.436-1.186c-0.121-0.082-0.277-0.113-0.43-0.073c-0.281,0.075-0.449,0.362-0.374,0.644l0.001,0.006 c0.155,0.584,0.326,1.14,0.482,1.686c0.153,0.545,0.3,1.077,0.412,1.597c0.237,1.035,0.359,2.018,0.306,2.87 c-0.058,0.858-0.267,1.565-0.638,2.311c-0.367,0.744-0.915,1.536-1.561,2.436c-0.638,0.901-1.377,1.909-1.988,3.16 c-0.305,0.624-0.566,1.312-0.739,2.045c-0.169,0.732-0.244,1.507-0.196,2.26C57.113,24.815,57.27,25.54,57.507,26.203z'
    />
  </svg>
)

export default BBQ_Icon