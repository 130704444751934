import React from 'react'

const Drupal_Icon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={"icons svg "+props.className} width={props.width} height={props.height} viewBox='0 0 128 128' aria-labelledby={props.iconTitle}>
    <title id={props.iconTitle}>Drupal Icon</title>
    <path d='M91.086 24.929c-6.352-3.956-12.344-5.514-18.336-9.469-3.717-2.517-8.869-8.51-13.186-13.663-.838 8.27-3.355 11.626-6.23 14.022-6.113 4.795-9.949 6.232-15.222 9.109-4.435 2.277-28.524 16.659-28.524 47.58 0 30.923 26.008 53.693 54.891 53.693 28.887 0 53.934-20.974 53.934-52.734s-23.491-46.142-27.327-48.538zm.693 89.994c-.6.6-6.113 4.435-12.584 5.034-6.473.599-15.221.96-20.496-3.835-.838-.84-.598-2.037 0-2.517.6-.479 1.08-.84 1.799-.84s.6 0 .959.24c2.396 1.917 5.992 3.476 13.662 3.476 7.672 0 13.064-2.158 15.461-3.955 1.08-.84 1.559-.119 1.678.359.121.48.361 1.2-.479 2.038zm-20.974-10.906c1.318-1.199 3.477-3.115 5.514-3.955 2.037-.838 3.117-.719 5.033-.719 1.918 0 3.955.119 5.395 1.079 1.438.958 2.277 3.116 2.756 4.314s0 1.917-.959 2.396c-.838.48-.959.24-1.797-1.316-.84-1.559-1.559-3.117-5.754-3.117s-5.514 1.438-7.551 3.117c-2.037 1.678-2.756 2.277-3.475 1.316-.719-.958-.481-1.916.838-3.115zm31.761.959c-4.314-.359-12.945-13.783-18.459-14.022-6.949-.24-22.053 14.501-33.918 14.501-7.19 0-9.347-1.077-11.745-2.637-3.596-2.516-5.393-6.352-5.273-11.625.12-9.349 8.869-18.098 19.895-18.218 14.023-.118 23.73 13.903 30.803 13.783 5.992-.119 17.498-11.864 23.131-11.864 5.992 0 7.672 6.232 7.672 9.947s-1.199 10.427-4.076 14.621c-2.877 4.195-4.674 5.754-8.03 5.514z'
    />
  </svg>
)

export default Drupal_Icon